import { Crop } from './crop';
import { User } from './user';
import { Model } from './model';
import { Expose, Transform, Type } from 'class-transformer';
import moment, { Moment } from 'moment';

class Report extends Model {
  @Expose()
  public farmer: string;

  @Expose()
  public field: string;

  @Expose()
  public area: number;

  @Expose()
  public city: string;

  @Expose({ name: 'state_id' })
  public stateId: number;

  @Expose({ name: 'crop_id' })
  public cropId: number;

  @Expose({ name: 'user_id' })
  public userId: number;

  @Expose()
  @Type(() => Crop)
  public crop?: Crop;

  @Expose()
  @Type(() => User)
  public user?: User | null;

  constructor(
    id: number | null,
    createdAt: Moment,
    updatedAt: Moment,
    deletedAt: Moment | null,
    farmer: string,
    field: string,
    area: number,
    city: string,
    stateId: number,
    cropId: number,
    userId: number,
    crop: Crop
  ) {
    super(id, createdAt, updatedAt, deletedAt);

    this.farmer = farmer;
    this.field = field;
    this.area = area;
    this.city = city;
    this.stateId = stateId;
    this.cropId = cropId;
    this.userId = userId;
    this.crop = crop;
  }
}

export { Report };
