import { CropSubstance } from './crop-substance';
import { Model } from './model';
import { Moment } from 'moment';
import { Expose, Type } from 'class-transformer';

class Crop extends Model {
  @Expose()
  public name: string;

  @Expose()
  @Type(() => CropSubstance)
  public exportation?: CropSubstance[];

  @Expose()
  @Type(() => CropSubstance)
  public extraction?: CropSubstance[];

  constructor(
    id: number | null,
    createdAt: Moment,
    updatedAt: Moment,
    deletedAt: Moment | null,
    name: string,
    exportation: CropSubstance[],
    extraction: CropSubstance[]
  ) {
    super(id, createdAt, updatedAt, deletedAt);

    this.name = name;
    this.exportation = exportation;
    this.extraction = extraction;
  }
}

export { Crop };
