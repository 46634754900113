import { Expose } from 'class-transformer';
import { Model } from './model';
import { Moment } from 'moment';

type SubstanceUnit = 'kg' | 'g';

enum SubstanceUnitString {
  kg = 'Kg',
  g = 'g',
}

class Substance extends Model {
  @Expose()
  public code: string;

  @Expose({ name: 'utf8_code' })
  public utf8Code: string;

  @Expose()
  public unit: SubstanceUnit;

  constructor(
    id: number | null,
    createdAt: Moment,
    updatedAt: Moment,
    deletedAt: Moment | null,
    code: string,
    utf8Code: string,
    unit: SubstanceUnit
  ) {
    super(id, createdAt, updatedAt, deletedAt);

    this.code = code;
    this.utf8Code = utf8Code;
    this.unit = unit;
  }
}

export { Substance, SubstanceUnit, SubstanceUnitString };
