import { Model } from './model';
import { Moment } from 'moment';
import { Substance } from './substance';
import { Expose, Type } from 'class-transformer';

class CropSubstance extends Model {
  @Expose()
  public value: number;

  @Expose({ name: 'crop_id' })
  public cropId: number;

  @Expose({ name: 'substance_id' })
  public substanceId: number;

  @Expose()
  @Type(() => Substance)
  public substance: Substance;

  constructor(
    id: number | null,
    createdAt: Moment,
    updatedAt: Moment,
    deletedAt: Moment,
    value: number,
    cropId: number,
    substanceId: number,
    substance: Substance
  ) {
    super(id, createdAt, updatedAt, deletedAt);

    this.value = value;
    this.cropId = cropId;
    this.substanceId = substanceId;
    this.substance = substance;
  }
}

export { CropSubstance };
